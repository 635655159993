import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThirdwebProvider, embeddedWallet } from '@thirdweb-dev/react';
import { Polygon, Mumbai } from '@thirdweb-dev/chains';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThirdwebProvider 
      activeChain={Polygon}
      clientId={process.env.REACT_APP_TW_CLIENT_ID}
      supportedChains={[Polygon, Mumbai]}
      sdkOptions={{
        gasless: {
          openzeppelin: {
            relayerUrl: process.env.REACT_APP_OZ_RELAYER_URL,
            useEOAForwarder: true
          },
        },
      }}
      supportedWallets={[
        embeddedWallet({
          auth: {
            options: [
              "email",
              "google",
            ],
          },
          recommended:true,
        }),
      ]}
    >
      <Router>
        <App />
      </Router>
    </ThirdwebProvider>
  </React.StrictMode>
);

reportWebVitals();