import axios from 'axios';

// Function to make a GET request
export async function searchData(endpoint,payload) {
    try {
        const url = `${process.env.REACT_APP_BUBBLE_URL}${endpoint}`
        const header = {
            Authorization: `Bearer ${process.env.REACT_APP_BUBBLE_TOKEN}`
        }
        const params = {
            constraints: payload
        };
        const response = await axios.get(url, { params, headers: header });
        return response.data.response.results
    } catch (error) {
        console.error(error);
    }
}

export async function getData(endpoint,uid) {
    try {
        const url = `${process.env.REACT_APP_BUBBLE_URL}${endpoint}/${uid}`
        const header = {
            Authorization: `Bearer ${process.env.REACT_APP_BUBBLE_TOKEN}`
        }
        const response = await axios.get(url, { headers: header });
        return response.data.response
    } catch (error) {
        console.error(error);
    }
}