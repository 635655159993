import React, { useState } from 'react';
import '../App.css';
import './Components.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faStar, faClockRotateLeft, faMessage } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  const contractAddress = "0x7C204492Bd71fB4306dCA8abAC33a202eAA9f4B6";
  const tokenId = "0";
  const blockScanUrl = `https://mumbai.polygonscan.com/nft/${contractAddress}/${tokenId}`;

  const NavbarItems = [
    { name: 'Website', icon: faGlobe, link: 'https://www.aieagolf.com'},
    { name: 'Membership', icon: faStar, link: 'https://membership.aieagolf.com' },
    { name: 'Social', icon: faMessage, link: 'https://www.instagram.com/aieagolf'},
    { name: 'History', icon: faClockRotateLeft, link: blockScanUrl },
  ];

  const [selectedNavbarItem, setSelectedNavbarItem] = useState(null);
  const selectedColor = '#d8fd00';
  const defaultColor = '#000';

  return (
    <div className="Navbar">
      <div className="Navbar-Row">
        {NavbarItems.map((item) => (
          <a href={item.link} target='_blank' rel='noopener noreferrer'
            key={item.name}
            className={`Navbar-Item ${selectedNavbarItem === item.name ? 'selected' : ''}`} 
            onClick={() => setSelectedNavbarItem(item.name)}
          >
            <FontAwesomeIcon 
                icon={item.icon} 
                size="xl" 
                color={selectedNavbarItem === item.name ? selectedColor : defaultColor} 
            />
            <span 
              className={`Navbar-Text ${selectedNavbarItem === item.name ? 'selected' : ''}`} 
            >
              {item.name}
            </span>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Navbar;