import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { Alchemy, Network } from 'alchemy-sdk';
import { Web3Button, useAddress, useContract, useClaimNFT, useEmbeddedWalletUserEmail } from '@thirdweb-dev/react';
import Navbar from './components/Navbar';
import Menubar from './components/Wallet';
import { getData, searchData } from './utils/ApiRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import PassImage from './img/launch_nft2.png';

const config = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.MATIC_MAINNET,
};
const alchemy = new Alchemy(config);

function App() {
  
  const [product, setProduct] = useState(null)
  const [profileId, setProfileId] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [isClaimSuccess, setIsClaimSuccess] = useState(false);

  const [isClaimed, setIsClaimed] = useState(false);

  const contractAddress = "0xA4e5322cc07260ac7162b65B45B3aF676DcDc3Ff";
  const { contract } = useContract(contractAddress);
  const { mutate: claimNFT, isSuccess, isLoading, error } = useClaimNFT(contract);
  const address = useAddress();
  const email = useEmbeddedWalletUserEmail();
  const emailAddress = email.data;

  const [tokenId, setTokenId] = useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const pk = searchParams?.get('pk1') || searchParams?.get('u');

  const fetchProduct = async (productId) => {
    const response = await getData('Product', productId);
    return response;
  };

  const fetchInventoryItem = async (pk) => {
    const inventoryItemConstraints = `[{"key":"publicKey","constraint_type":"equals","value":"${pk.toLowerCase()}"}]`;
    try {
      const searchResponse = await searchData('InventoryItem', inventoryItemConstraints);

      if (searchResponse[0]?.Product) {
        const productId = searchResponse[0].Product;
        const productResponse = await fetchProduct(productId);
        const productAttributeConstraints = `[{"key":"Product","constraint_type":"equals","value":"${productId}"}]`;
        const attributesResponse = await searchData('ProductAttribute', productAttributeConstraints);
        setProduct({
          id: productId,
          name: productResponse.Name,
          description: productResponse.Description,
          image: `https:${productResponse.Image}`,
          type: productResponse.ProductType,
          contractAddress: productResponse.SmartContract,
          account: productResponse.Account,
          attributes: [
            { trait_type: 'Product Type', value: productResponse.ProductType },
            ...attributesResponse.map(r => ({ trait_type: r.Name, value: r.Value }))
          ]
        });
      }
    } catch (error) {
      console.error('Error fetching inventory item data:', error);
    }
  };

  const fetchProfile = async (email, address) => {
    try {
      const getResponse = await getProfile(email);
      if (getResponse.data.length === 0) {
        const createResponse = await createProfile(email, address);
        const profileId = createResponse.data.id;
        setProfileId(profileId);
        if (profileId) {
          await addProfileToList(profileId, email);
        }
      } else {
        const profileId = getResponse.data[0].id;
        setProfileId(profileId);
        if (profileId) {
          await updateProfile(profileId, address);
          await addProfileToList(profileId, email);
        }
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const addProfileToList = async (profileId, email) => {
    try {
      const listIds = process.env.REACT_APP_KLAVIYO_LIST_IDS.split(',');
      listIds.forEach(async (listId) => {
        const listProfile = await getListProfile(email, listId);
        if (listProfile.data.length === 0) {
          await subscribeProfileToList(profileId, email, listId);
        }
      });
    } catch (error) {
      console.error('Error adding profile to list:', error);
    }
  }

  const verifyOwnership = useCallback(async () => {
    if (contractAddress && address) {
      const isClaimed = await alchemy.nft.verifyNftOwnership(address, contractAddress);
      setIsClaimed(isClaimed);
      let options = {
        contractAddresses: [contractAddress],
      };
      const response = await alchemy.nft.getNftsForOwner(address, options);
      if (response.totalCount > 0) {
        setTokenId(response.ownedNfts[0].tokenId);
      }
    }
  }, [contractAddress, address]);


  async function getProfile(email) {
    const response = await fetch(`/api/getProfile?email=${email}`);
    return response.json();
  };

  async function createProfile(email, address) {
    const response = await fetch('/api/createProfile', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, address }),
    });
    return response.json();
  };

  async function updateProfile(profileId, address) {
    const response = await fetch('/api/updateProfile', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ profileId, address }),
    });
    return response.json();
  }

  async function getListProfile(email, listId) {
    const response = await fetch(`/api/getListProfile?email=${email}&listId=${listId}`);
    return response.json();
  }

  async function subscribeProfileToList(profileId, email, listId) {
    
      await fetch('/api/addProfileToList', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ profileId, listId }),
      });

      await fetch('/api/subscribeProfile', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ profileId, email, listId }),
      });
  }

  useEffect(() => {
    if (pk) {
      fetchInventoryItem(pk);
    }
  }, [pk]);

  useEffect(() => {
    if (emailAddress && address) {
      fetchProfile(emailAddress, address);
    }
  }, [emailAddress, address]);

  useEffect(() => {
    if (profileId && emailAddress) {
      addProfileToList(profileId, emailAddress);
    }
  }, [profileId, emailAddress]);

  useEffect(() => {
    verifyOwnership();
  }, [verifyOwnership]);

  useEffect(() => {
    if (isSuccess) {
      setShowPopup(true);
      setIsClaimSuccess(true);
    }
  }, [isSuccess]);

  if (!pk) {
    return (
    <div className='Loading'>
        <span className='Alert-Error'>Read Error</span>
        <span>Unable to read tag ID. Please tap the chip again.</span>
    </div>
    );
  }


  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
        <Menubar />
        <div className='Content'>
          {!product && (
            <div className='Loading'>
              <span className='Alert-Loading'>Loading</span>
              <span>Retrieving data from the blockchain.</span>
            </div>
          )}
          {product && (
            <div className='Product-Container'>
              <img src={product.image} className="Product-Image" alt="logo" />
              <div className='Product-Detail-Container'>
                <div className='Token-Container'>
                  <p className='Token'>1 of 1</p>
                </div>
                <div className='Title-Container'>
                  <h1>{product.name}</h1>
                  <span>{product.description}</span>
                </div>
                <div className='Attributes-Container'>
                  <h3>Attributes</h3>
                  <div className='Attributes'>
                    {product.attributes.map((item) => (
                      <div className='Attribute' key={item.trait_type}>
                        <div className='Attribute-Title'>{item.trait_type}</div>
                        {item.value}
                      </div>
                    ))}
                  </div>
                </div>
                {address && !isClaimed && (
                  <>
                    <Web3Button
                      contractAddress={contractAddress}
                      action={() => {claimNFT({ to: address, quantity: 1 })}}
                      className='btn btn-primary claim'
                    >
                      {!isLoading && !isSuccess && <div>Claim Pass</div>}
                      {isLoading && <div>Processing...</div>}
                      {isSuccess && <div>Claimed!</div>}
                    </Web3Button>
                  </>
                )}
                {address && isClaimed && (
                  <button 
                    className='btn btn-primary claim' 
                    onClick={() => window.open(`https://opensea.io/assets/matic/${contractAddress}/${tokenId}`, '_blank')}
                  >
                    View your pass
                  </button>
                )}
                {isClaimSuccess && showPopup && (
                  <div className="popup">
                    <div className="popup-inner">
                      <div className="popup-tab">
                        <div className="close-icon" onClick={() => { setShowPopup(false); setIsClaimSuccess(false); setIsClaimed(true); verifyOwnership() }}>
                          <FontAwesomeIcon
                            icon={faXmark}
                            size="lg" 
                            color="#000"
                          />
                          </div>
                      </div>
                      <div className='popup-container'>
                      <div className='popup-header'>
                        <span className='popup-title'>Pass Claimed!</span>
                      </div>
                      <div className='popup-body'>
                        <img className='popup-image' src={PassImage} alt="Launch Pass" />
                      </div>
                      </div>
                    </div>
                  </div>
                )}
                {error && <span>Error claiming the NFT</span>}
              </div>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;