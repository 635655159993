import React from 'react';
import '../App.css';
import './Components.css';
import { ConnectWallet, lightTheme } from '@thirdweb-dev/react';

function Menubar() {
    return (
      <div className="Menu">
        <div className="Menu-Row">
          <div className="Wallet">
            <ConnectWallet
                  welcomeScreen={{
                    title: "Welcome to AIEA Golf",
                    img: {
                      src: 'https://www.aieagolf.com/cdn/shop/files/AIEA_logo_-_black.png?v=1657309469&width=750',
                      width: 150,
                    }
                  }}
                  btnTitle="Get Started"
                  theme={lightTheme({
                    colors: {
                      accentText: "#00000",
                      accentButtonBg: "#00000",
                      accentButtonText: "#000000",
                      primaryButtonBg: "#d8fd00",
                      primaryButtonText: "#00000",
                      primaryText: "#000000",
                      connectedButtonBg: "#d8fd00",
                      connectedButtonBgHover: "#e6fd65",
                    },
                  })}
                  className='btn wallet'
                  switchToActiveChain={true}
            />
            </div>
          </div>
        </div>
    );
}

export default Menubar;